<template>
  <div class="content-grid">
    <div class="content-grid-list">
      <slot name="list" />
    </div>
    <div class="content-grid-form">
      <slot name="form" />
    </div>
  </div>
</template>
